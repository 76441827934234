import { AsProps, CSSProps, styled } from '@neui/core';
import { forwardRef } from 'react';
import { Root } from '@radix-ui/react-accordion';
import { interaction_arrows_chevronDown } from '@neui/styleguide-commerzbank';

import {
  BannerCollapsibleItem,
  BannerCollapsibleItemProps,
} from '../atoms/BannerCollapsibleItem';
import {
  BannerCollapsibleContent,
  BannerCollapsibleContentProps,
} from '../atoms/BannerCollapsibleContent';
import {
  BannerCollapsibleTrigger,
  BannerCollapsibleTriggerProps,
} from '../atoms/BannerCollapsibleTrigger';

export type BannerCollapsibleProps = AsProps &
  CSSProps &
  BannerCollapsibleContentProps &
  Omit<BannerCollapsibleItemProps, 'value'> &
  Pick<BannerCollapsibleTriggerProps, 'headline'> & {};

const StyledRoot = styled(Root, {
  padding: '$component-4',
  backgroundColor: '$colors$neutral100',
  borderRadius: '$space$component-4',
  '@md': {
    padding: '$component-6',
  },
});

export const BannerCollapsible = forwardRef<
  HTMLDivElement,
  BannerCollapsibleProps
>(
  (
    {
      icon,
      badgeContent,
      variant,
      look,
      size,
      actions,
      customIcon,
      headline,
      disabled,
      children,
      ...rest
    },
    forwardedRef,
  ) => {
    return (
      <StyledRoot collapsible type="single" ref={forwardedRef}>
        <BannerCollapsibleItem disabled={disabled} value={'banner'}>
          <BannerCollapsibleTrigger
            icon={icon}
            variant={variant}
            size={size}
            headline={headline}
          ></BannerCollapsibleTrigger>
          <BannerCollapsibleContent
            icon={interaction_arrows_chevronDown}
            variant={variant}
            look={look}
            size={size}
            actions={actions}
            customIcon={customIcon}
          >
            {children}
          </BannerCollapsibleContent>
        </BannerCollapsibleItem>
      </StyledRoot>
    );
  },
);
BannerCollapsible.displayName = 'BannerCollapsible';

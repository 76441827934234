export interface CategoryInfo {
  icon: React.ComponentType | string;
  tag: string;
  label: string;
  altText?: string;
  description?: string;
}

export const categoryMap = {
  'cds-wissen-gpp-en': new Map<string, CategoryInfo>([
    [
      '/information-and-settings/',
      {
        icon: '/icons/gear.svg',
        tag: 'informationandsettingstop',
        label: 'Information & Settings',
        altText: '',
        description: 'Basic Information for GPP, photoTAN and Personal Data',
      },
    ],
    [
      '/orders/',
      {
        icon: '/icons/calendar-coin.svg',
        tag: 'orderstop',
        label: 'Orders',
        altText: '',
        description: 'Support for Payments and Orders',
      },
    ],
    [
      '/accounts-and-transactions/',
      {
        icon: '/icons/currency-coin.svg',
        tag: 'accountsandtransactionstop',
        label: 'Accounts & Transactions',
        altText: '',
        description: 'Instructions for Transactions and Accounts',
      },
    ],
    [
      '/administration/',
      {
        icon: '/icons/checklist.svg',
        tag: 'administrationtop',
        label: 'Administration',
        altText: '',
        description: 'Information on Administrative Aspect',
      },
    ],
  ]),
  'cds-wissen-gpp-de': new Map<string, CategoryInfo>([
    [
      '/konto-und-zahlungsverkehr/',
      {
        icon: '/icons/cards-document-light.svg',
        tag: 'kontoundzahlungsverkehrtop',
        label: 'Konto & Zahlungsverkehr',
        altText: '',
        description:
          'Corporate Card, Einzahlkarten, Firmenkonto, Girocard-Maestro Card, Zahlungsverkehr',
      },
    ],
    [
      '/anlage-und-risikomanagement/',
      {
        icon: '/icons/vault-stocks-light.svg',
        tag: 'anlageundrisikomanagementtop',
        label: 'Anlage & Risikomanagement',
        altText: '',
        description:
          'Depot und Wertpapiere, Client Identifier / LEI, FX Live Trader, Live Confirm, Termingeld',
      },
    ],
    [
      '/finanzierung-und-aussenhandel/',
      {
        icon: '/icons/banknote-sent-light.svg',
        tag: 'finanzierungundaußenhandeltop',
        label: 'Finanzierung & Außenhandel',
        altText: '',
        description: 'Avale, Kredite, Leasing',
      },
    ],
    [
      '/online-banking/',
      {
        icon: '/icons/devices-card-light.svg',
        tag: 'onlinebankingtop',
        label: 'Online Banking',
        altText: '',
        description:
          'Elektronisches Postfach, Firmenkundenportalverwaltung, GPP: Aufträge, GPP: Konten & Umsätze, GPP: Verwaltung, Services, Vollmachten & Berechtigungen, photoTAN, Übertragungskanäle',
      },
    ],
    [
      '/corporate-banking-app/',
      {
        icon: '/icons/mobile-interact.svg',
        tag: '',
        label: 'Corporate Banking App',
        altText: '',
        description: 'Verwaltung',
      },
    ],
  ]),
  'cds-wissen': new Map<string, CategoryInfo>([
    [
      '/online-banking/',
      {
        icon: '/icons/devices-card.svg',
        tag: 'digitalbankingtop',
        label: 'Online Banking',
        altText: 'Devices Card',
        description:
          'Online Banking-Zugang, Banking-App, TAN-Verfahren, Elektronisches Postfach, Basiswissen',
      },
    ],
    [
      '/zahlungsarten/',
      {
        icon: '/icons/coinstack-hand.svg',
        tag: 'kontenundkartentop',
        label: 'Zahlungsarten',
        altText: 'Coinstack Hand',
        description:
          'Geld senden, Kontaktlos bezahlen,  Bargeld & Schecks, Dauerauftrag & Lastschrift',
      },
    ],
    [
      '/konto/',
      {
        icon: '/icons/vault-stocks.svg',
        tag: 'anlagenundwertpapieretop',
        label: 'Konto',
        altText: 'Vault Stocks',
        description:
          'Konto eröffnen, Konto wechseln, Konto verwalten, Multibanking, Vollmacht & Nachlass, Pfändung',
      },
    ],
    [
      '/karten/',
      {
        icon: '/icons/cards.svg',
        tag: 'finanzierungundkredittop',
        label: 'Karten',
        altText: 'Cards',
        description: 'Girocard, Kreditkarte, Virtuelle Karte, Basiswissen',
      },
    ],
    [
      '/sparen-und-finanzierung/',
      {
        icon: '/icons/piggybank-hammer-graph.svg',
        tag: 'versicherungundvorsorgetop',
        label: 'Sparen & Finanzieren',
        altText: 'Piggybank Hammer Graph',
        description:
          'Wertpapier, Kredit & Finanzierung, Ersparnisse, Steuerinformationen',
      },
    ],
    [
      '/ebics-und-hbci/',
      {
        icon: '/icons/card-laptop.svg',
        tag: 'datenschutzundsicherheittop',
        label: 'EBICS und HBCI',
        altText: 'Card Laptop',
        description:
          'Sicherheitsmedien & Star Money, Geschäftsvorfälle & Zahlungsverkehr, Basiswissen',
      },
    ],
  ]),
};

import { HStack, VStack } from '@neui/layout';
import {
  Typography,
  Headline,
  interaction_arrows_arrowRight,
} from '@neui/styleguide-commerzbank';

import { dynamicHref } from '@utils/helpers/linkHelpers';
import { StyledSection } from 'page-templates/OBIndexPage';
import { isGpp } from '@utils/DataFetcher';
import { $t } from '@utils/i18n';

import { IconLink } from './neui-components/atoms/IconLink';

export const ChangeServicePortal = () => {
  const iconLinkUrl = dynamicHref(
    !isGpp
      ? 'https://service-firmenkunden.commerzbank.com/'
      : 'https://www.commerzbank.de/service/',
  );
  const headline = !isGpp
    ? 'Als Firmenkunde unterwegs?'
    : $t('CHANGE_SERVICEPORTAL_TITLE');

  return (
    <StyledSection>
      <VStack
        flexDirection={{ base: 'column', md: 'row-reverse' }}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <HStack justifyContent={'end'} paddingRight={{ sm: 0, md: 50 }}>
          <img src={dynamicHref('/icons/office-tree.svg')} loading="lazy" />
        </HStack>
        <HStack alignItems={'center'}>
          <VStack spacing={24}>
            <Headline type="h3">{headline}</Headline>
            <Typography size={5} weight={'book'} aria-level={3} role="heading">
              {$t('CHANGE_SERVICEPORTAL_SUBLINE')}
            </Typography>
            <IconLink
              iconPosition="right"
              iconAriaHidden={true}
              icon={interaction_arrows_arrowRight}
              animationDirection="right"
              css={{ width: 'fit-content' }}
              href={iconLinkUrl}
            >
              {$t('CHANGE_SERVICEPORTAL_ICONLINK')}
            </IconLink>
          </VStack>
        </HStack>
      </VStack>
    </StyledSection>
  );
};

import {
  people___profileHistory,
  banking_transfer_transferslip,
  banking_cash_coinArrowright,
  object_devices_laptop,
  banking___phototan,
  banking___pincode,
  object_document_documentArrow,
  banking_graphs_benchmark,
  object___calender,
  banking_card_blockCard,
} from '@neui/styleguide-commerzbank';

import type { MakeLinkFunc } from './helpers/linkHelpers';

type IconGroupInfo = {
  label: string;
  url: string;
  icon: typeof people___profileHistory;
};

export const iconGroupInfo = (makeLink: MakeLinkFunc): IconGroupInfo[] => {
  return [
    {
      label: 'Personendaten ändern',
      icon: people___profileHistory,
      url: makeLink(
        'www',
        '/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Person/SP02_Personendaten_aendern_Int',
      ),
    },
    {
      label: 'Kontaktdaten ändern',
      icon: banking_transfer_transferslip,
      url: makeLink(
        'kunden',
        '/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Person/SP23_Kontaktdaten',
      ),
    },
    {
      label: 'Überweisungslimit ändern',
      icon: banking_cash_coinArrowright,
      url: makeLink('kunden', '/dailybanking/change-transfer-limit'),
    },
    {
      label: 'Online Banking beantragen',
      icon: object_devices_laptop,
      url: makeLink(
        'www',
        '/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/TNV/ST10_TNV_Anmeldung_DigitalBanking_AutoIdent_Int',
      ),
    },
    {
      label: 'PhotoTAN aktivieren',
      icon: banking___phototan,
      url: '/wie-kann-ich-phototan-aktivieren/',
    },
    {
      label: 'Online Banking Pin vergessen',
      icon: banking___pincode,
      url: makeLink(
        'kunden',
        '/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/TNV/ST07_TNV_PIN_anfordern',
      ),
    },
    {
      label: 'Karte sperren',
      icon: banking_card_blockCard,
      url: makeLink('kunden', '/cards/carddetails'),
    },
    // {
    //   label: 'Neues Konto eröffnen',
    //   icon: interaction___add,
    //   url: makeLink('kunden', '/konten-zahlungsverkehr/produkte/girokonten/'),
    // },
    {
      label: 'Auftragsübersicht',
      icon: banking_graphs_benchmark,
      url: makeLink('kunden', '/orderoverview/orderoverview'),
    },
    {
      label: 'Termin vereinbaren',
      icon: object___calender,
      url: makeLink('kunden', '/chat/onlineappointment#kunde'),
    },
    {
      label: 'Termin verwalten',
      icon: object___calender,
      url: makeLink('kunden', '/chat/onlineappointment/overview'),
    },
    {
      label: 'Pin ändern',
      icon: banking___pincode,
      url: makeLink('kunden', '/dailybanking/change-pin'),
    },
    {
      label: 'Kontopfändung begleichen',
      icon: object_document_documentArrow,
      url: makeLink(
        'kunden',
        '/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Sonstiges/SS03_Pfaendung_FreigabeKunde',
      ),
    },
  ];
};

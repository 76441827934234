import {
  people___profileHistory,
  banking_transfer_transferslip,
  banking_cash_coinArrowright,
  object_devices_laptop,
  banking___phototan,
  banking___pincode,
  object_document_documentArrow,
  object___shieldCheckmark,
  communication___envelope,
  location___globe,
} from '@neui/styleguide-commerzbank';

import { MakeLinkFunc } from './helpers/linkHelpers';

type IconGroupInfo = {
  label: string;
  url: string;
  icon: typeof people___profileHistory;
};

export const cdsIconGroupInfo = (makeLink: MakeLinkFunc): IconGroupInfo[] => {
  return [
    {
      label: 'Personendaten ändern',
      icon: people___profileHistory,
      url: makeLink(
        'www',
        '/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Person/SP02_Personendaten_aendern_Int',
      ),
    },
    {
      label: 'Kontaktdaten ändern',
      icon: banking_transfer_transferslip,
      url: makeLink(
        'kunden',
        '/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Person/SP23_Kontaktdaten',
      ),
    },
    {
      label: 'Überweisungslimit ändern',
      icon: banking_cash_coinArrowright,
      url: makeLink('kunden', '/dailybanking/change-transfer-limit'),
    },
    {
      label: 'Online Banking beantragen',
      icon: object_devices_laptop,
      url: makeLink(
        'www',
        '/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/TNV/ST10_TNV_Anmeldung_DigitalBanking_AutoIdent_Int',
      ),
    },
    {
      label: 'PhotoTAN aktivieren',
      icon: banking___phototan,
      url: '/wie-kann-ich-phototan-aktivieren/',
    },
    {
      label: 'Online Banking Pin vergessen',
      icon: banking___pincode,
      url: makeLink(
        'kunden',
        '/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/TNV/ST07_TNV_PIN_anfordern',
      ),
    },
    {
      label: 'Kontopfändung begleichen',
      icon: object_document_documentArrow,
      url: makeLink(
        'www',
        '/prozess/WebObjects/ProzessCenter.woa/wa/default?path=/pk_sp/de/Sonstiges/SS03_Pfaendung_FreigabeKunde',
      ),
    },
  ];
};

export const fkIconGroupInfo = (isGppDE: boolean): IconGroupInfo[] => {
  if (isGppDE)
    return [
      {
        label: 'photoTAN aktivieren',
        icon: banking___phototan,
        url: '/wie-aktiviere-ich-das-phototan-verfahren/',
      },
      {
        label: 'Berechtigungen aktualisieren',
        icon: object___shieldCheckmark,
        url: '/wie-kann-ich-meine-berechtigungen-aktualisieren/',
      },
      {
        label: 'Initialisierungsbrief Bankzugang',
        icon: communication___envelope,
        url: '/wie-erhalte-ich-den-initialisierungsbrief-fuer-die-freischaltung-meines-ersten-bankzugangs/',
      },
      {
        label: 'Aktivierungsbrief photoTAN',
        icon: communication___envelope,
        url: '/wie-kann-ich-einen-aktivierungsbrief-und-ein-lesegeraet-fuer-das-phototan-verfahren-anfordern/',
      },
      {
        label: 'Kontoauszüge exportieren',
        icon: object_document_documentArrow,
        url: '/wie-kann-ich-kontoauszuege-exportieren/',
      },
      {
        label: 'SEPA-Überweisung tätigen',
        icon: banking_cash_coinArrowright,
        url: '/wie-taetige-ich-eine-sepa-ueberweisung/',
      },
      {
        label: 'Auslandsüberweisung tätigen',
        icon: banking_cash_coinArrowright,
        url: '/wie-taetige-ich-eine-auslandsueberweisung/',
      },
      // {
      //   label: 'Anzeigeberechtigung Konto',
      //   icon: symbols___uncategorized,
      //   url: '/wie-kann-ich-meine-anzeigeberechtigungen-fuer-konten-pruefen-und-aktualisieren/',
      // },
      // {
      //   label: 'photoTAN Push einrichten',
      //   icon: symbols___uncategorized,
      //   url: '/wie-benutze-ich-das-phototan-push-verfahren/',
      // },
      // {
      //   label: 'Fremdbank einbinden',
      //   icon: symbols___uncategorized,
      //   url: '/wie-binde-ich-eine-fremdbank-in-global-payment-plus-ein/',
      // },
    ];
  else {
    return [
      {
        label: 'Change Language in GPP',
        icon: location___globe,
        url: '/how-do-i-change-the-language-in-global-payment-plus/',
      },
      {
        label: 'Initialisation Letter Bank Access',
        icon: communication___envelope,
        url: '/how-do-i-receive-the-initialisation-letter-for-the-activation-of-my-first-bank-access/',
      },
      {
        label: 'Activation Letter photoTAN',
        icon: communication___envelope,
        url: '/how-can-i-request-an-activation-letter-for-my-phototan-reader-app/',
      },
      {
        label: 'photoTAN Activation',
        icon: banking___phototan,
        url: '/how-do-i-activate-phototan/',
      },
      {
        label: 'Export Account Statements',
        icon: object_document_documentArrow,
        url: '/how-can-i-export-account-statements/',
      },
      {
        label: 'Change PIN',
        icon: banking___pincode,
        url: '/how-do-i-change-my-pin-for-logging-in/',
      },
      {
        label: 'Enter an Order',
        icon: banking_cash_coinArrowright,
        url: '/how-do-i-enter-an-order/',
      },
    ];
  }
};

import React from 'react';
import parse, { DomElement } from 'html-react-parser';

import { ArticlePageProps } from 'pages/[...slug]';
import { Metadata } from '@utils/DataFetcher';
import { SectionSeparator } from '@components/SectionSeparator/SectionSeparator';

import CdsParagraphRenderer from './ParagraphRenderer/CdsParagraphRenderer';
import HtmlTagIgnorer from './HtmlTagIgnorer/HtmlTagIgnorer';
import CdsULRenderer from './ULRenderer/CdsUlRenderer';
import CdsOLRenderer from './OLRenderer/CdsOLRenderer';
import CdsLIRenderer from './LIRenderer/CdsLIRenderer';

export type NewsHtmlParserProps = {
  content: string;
  linksMetadata?: ArticlePageProps['textLinks'];
  basename: Metadata['basename'];
};

const renderDomNode = (domNode: DomElement) => {
  const name = domNode.name || domNode.type;
  const defaultProps = { domNode, renderDomNode };
  let node;
  switch (name) {
    case 'p':
      node = <CdsParagraphRenderer {...defaultProps} />;
      break;
    case 'ul':
      node = <CdsULRenderer {...defaultProps} />;
      break;
    case 'ol':
      node = <CdsOLRenderer {...defaultProps} />;
      break;
    case 'li':
      node = <CdsLIRenderer {...defaultProps} />;
      break;
    case 'hr':
      node = <SectionSeparator />;
      break;
    case 'text':
      node = domNode.data;
      break;
    default:
      node = <HtmlTagIgnorer {...defaultProps} />;
  }
  return node;
};

export function NewsHtmlParser(props: NewsHtmlParserProps) {
  const { content } = props;
  return (
    <>
      {parse(content, {
        replace: (domNode) => renderDomNode(domNode),
      })}
    </>
  );
}
